<template>
    <div :class="['Standard', `alias-${alias}`]">
        <div>
            <div class="container">
                <Head></Head>
            </div>
            <div class="container content">
                <MainMenu ref="MainMenu"></MainMenu>
                <div class="Inner">
                    <div class="leftMenu">
                        <el-menu :default-active="activeIndex"
                                 v-if="getTree"
                                 mode="vertical"
                                 @select="handleSelect">
                            <el-menu-item :index="item.id" v-for="(item,i) in pages"
                                          :key="item.id">
                                {{isEn ? item.longtitle : item.pagetitle}}
                            </el-menu-item>
                        </el-menu>
                        <a href="https://info.flagcounter.com/lqM8" class="counter">
                            <img src="https://s01.flagcounter.com/count2/lqM8/bg_F5F2ED/txt_000000/border_CCCCCC/columns_3/maxflags_15/viewers_0/labels_0/pageviews_1/flags_0/percent_0/"
                                 alt="Flag Counter" border="0">
                        </a>
                    </div>

                    <div class="wrap" ref="wrap">
                        <MdxPage :alias="aliasToShow"></MdxPage>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import MainMenu from "../components/MainMenu";
    import Head from "../components/Head";
    import MdxPage from "../components/widgets/MdxPage";

    export default {
        name: "Standard",
        components: {Head, MainMenu, MdxPage},
        props: {
            alias: {
                type: String
            },
            params: {
                type: Object
            },
            title: {
                type: String
            },
            getTree: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                pageTitle: '',
                content: '',
                activeIndex: '',
                curIndex: '',
                pages: [],
                aliasToShow: ''
            }
        },
        computed: {
            locale() {
                return this.$store.getters.locale;
            },
            isEn() {
                return this.$store.getters.locale === 'en'
            }
        },

        mounted() {
            this.aliasToShow = this.alias;
            this.$refs.MainMenu.activeIndex = this.alias;
            if (this.getTree) {
                this.getPageTree();
            }
        },
        watch: {},
        methods: {
            refresh() {
            },
            getPageTree() {
                let self = this,
                    query = {
                        data: {
                            mod: 'get_pageTree',
                            alias: this.alias,
                        }
                    };
                self.$req(query).then(resp => {
                    self.pages = resp;
                    let _id = resp[0]['id'];
                    self.handleSelect(_id);
                    self.activeIndex = _id;
                })

            },
            showPage() {
                let self = this,
                    _page = self.pages.find(it => it.id === self.curIndex) || {};
                self.aliasToShow = _page.alias;
            },
            handleSelect(index) {
                let self = this;
                self.curIndex = index;
                self.$refs.wrap.scrollTo({
                    top: 0,
                    behavior: "smooth"
                });
                self.showPage();
            },


        },
    }
</script>

<style lang="scss" scoped>
    .wrap {
        padding-top: 15px;
        padding-bottom: 10px;
    }

    a.counter {
        display: inline-block;
        position: absolute;
        bottom: 20px;
        left: 65px;
    }
</style>